import type { ActionFunctionArgs, LoaderFunctionArgs, MetaFunction } from "react-router";
import { redirect } from "react-router";
import { Form, Link, useActionData, useNavigation } from "react-router";
import { useEffect } from "react";
import { motion, useAnimationControls } from "framer-motion";
import { authWithPassword, getSessionData, verifyAuthenticityToken } from "~/services/session.server";
import { AuthenticityTokenInput } from "~/services/session";
import { TextField } from "~/components/TextField";
import { useToaster } from "~/components/toasts/ToastProvider";
import { ShieldExclamationIcon } from "@heroicons/react/24/outline";
import { Button } from "~/components/Button";
import { z } from "zod";
import { getFormProps, useForm } from "@conform-to/react";
import { parseWithZod } from "@conform-to/zod";

const schema = z.object({
  email: z.string(),
  password: z.string(),
});

export async function action({ request }: ActionFunctionArgs) {
  await verifyAuthenticityToken(request);
  const formData = await request.formData();
  const submission = parseWithZod(formData, { schema });

  if (submission.status !== "success") {
    return submission.reply();
  }

  // this will redirect to /home if the login is successful
  await authWithPassword(submission.value.email, submission.value.password, request, "/inbox");

  return submission.reply({
    formErrors: ["InvalidCredentialsError"],
  });
}

export async function loader(args: LoaderFunctionArgs) {
  const session = await getSessionData(args.request);
  if (session && session.email) return redirect("/inbox");
  return null;
}

export const meta: MetaFunction = () => [
  { charset: "utf-8" },
  { title: "Bei Normando Anmelden" },
  { viewport: "width=device-width,initial-scale=1" },
];

export default function Login() {
  const lastResult = useActionData<typeof action>();
  const controls = useAnimationControls();
  const toaster = useToaster();
  const [form, fields] = useForm({
    defaultValue: { email: "", password: "" },
    lastResult,
    onValidate({ formData }) {
      const data = parseWithZod(formData, { schema });
      return data;
    },
  });
  useEffect(() => {
    if (form.errors) {
      form.errors.forEach((error) => {
        switch (error) {
          case "InvalidCredentialsError":
            // shake form
            controls.start({
              x: [null, 0, -12, 12, -6, 6, 0],
              transition: { duration: 1 },
            });
            toaster.toast({
              icon: <ShieldExclamationIcon className="h-5" />,
              message: (
                <div>
                  Du hast falsche Zugangsdaten eingegeben.
                  <br />
                  Bitte versuche es erneut.
                </div>
              ),
            });
            break;
          case "AuthRetryableFetchError":
            toaster.error({
              message: "Entschuldigung, der Server ist aktuell nicht erreichbar. Bitte versuche es später erneut.",
            });
            break;
          default:
            toaster.error({ message: "Es ist ein unerwarteter Fehler aufgetreten. Bitte versuche es später erneut." });
            break;
        }
      });
    }
  }, [form.errors]);

  const navigation = useNavigation();
  const isLoggingIn = navigation.formData?.get("intent") == "login";
  return (
    <div className="grow">
      <div className="flex flex-col px-4 h-full bg-zinc-50 items-center justify-stretch">
        <div className="grow w-full flex flex-col items-center justify-center py-8">
          <div className="shrink-0 pb-8 px-4 max-w-lg w-full flex items-start">
            <Link to="/login" className="flex items-center justify-center gap-2">
              <div className="p-1 rounded-md bg-zinc-950 text-white aspect-square w-6 h-6 inline-flex items-center justify-center">
                <span>N</span>
              </div>
              <h1 className="font-medium">Normando</h1>
            </Link>
          </div>
          <motion.div
            className="max-w-lg w-full p-8 bg-white rounded-3xl border border-zinc-100 shadow-2xl relative"
            animate={controls}
          >
            <div className="absolute w-20 left-1/2 -translate-x-1/2 top-4 h-2 rounded-full bg-zinc-50 border-zinc-200 border shadow-inner">
              <div className="absolute rounded-md h-16 w-12 left-1/2 border border-zinc-100/90 -translate-x-1/2 -translate-y-full mt-1 bg-white/10 backdrop-blur-sm shadow-sm"></div>
            </div>
            <h2 className="text-2xl pb-[1ex] font-semibold text-zinc-950">Anmelden</h2>
            <p className="text-sm text-zinc-700">Melde dich jetzt bei Normando an.</p>

            <Form method="post" {...getFormProps(form)}>
              <AuthenticityTokenInput />
              <fieldset disabled={isLoggingIn}>
                <div className="flex flex-col space-y-[2ex] pt-[2ex]">
                  <TextField
                    label="E-Mail Adresse"
                    name="email"
                    type="email"
                    autoComplete="email"
                    placeholder="E-Mail Adresse"
                    isRequired={true}
                    isReadOnly={isLoggingIn}
                    conformTo={fields.email}
                  />
                  <TextField
                    label="Passwort"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    placeholder="Passwort"
                    isRequired={true}
                    isReadOnly={isLoggingIn}
                    conformTo={fields.password}
                  />
                  <div className="justify-stretch flex items-center pt-[1ex]">
                    <Button
                      variant="primary"
                      showLoader={isLoggingIn}
                      type="submit"
                      name="intent"
                      value="login"
                      className="w-full"
                    >
                      Anmelden
                    </Button>
                  </div>
                </div>
              </fieldset>
            </Form>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
